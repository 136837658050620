import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Check, ChevronRight } from 'lucide-react';
import EndoBandLogo from '../assets/images/ENDOBAND.png';

function EndoBand() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Endolastic: Precision Bands for Advanced Tissue Management | Minimally Invasive</title>
        <meta name="description" content="Endolastic delivers innovative solutions for precision tissue management using advanced band ligation techniques, empowering professionals with versatile and minimally invasive tools." />
        <meta name="keywords" content="Endolastic, band ligation, tissue management, minimally invasive, professional medical tools" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Endolastic: Precision Bands for Advanced Tissue Management" />
        <meta property="og:description" content="Endolastic introduces advanced minimally invasive bands designed for versatile tissue management. Perfectly tailored for professional applications, enhancing precision and control." />
        <meta property="og:image" content="/OG-Endo.png" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Endolastic: Precision Bands for Advanced Tissue Management" />
        <meta name="twitter:description" content="Endolastic introduces advanced minimally invasive bands designed for versatile tissue management. Perfectly tailored for professional applications, enhancing precision and control." />
        <meta name="twitter:image" content="/OG-Endo.png" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Endolastic Precision Bands",
            "description": "Endolastic provides precision medical tools for tissue management using advanced band ligation techniques. Designed for professional use in minimally invasive applications.",
            "manufacturer": "Endolastic"
          })}
        </script>
      </Helmet>

      <header className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-24">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">EndoBand</h1>
          <p className="text-xl md:text-2xl max-w-3xl">
            Precision Tissue Management System
          </p>
          <p className="text-lg md:text-xl mt-4 max-w-3xl">
            Advanced technology for encircling, lifting, compressing, and treating tissue with unmatched precision.
          </p>
        </div>
      </header>

      <main className="container mx-auto px-4 py-16">
        <section className="mb-16">
          <div className="flex flex-col items-center mb-12">
            <img 
              src={EndoBandLogo} 
              alt="EndoBand Logo" 
              className="h-32 w-auto"
            />
          </div>
          <p className="text-lg mb-6">
          EndoBand represents the latest advancement in ligation-based tissue management technology, enabling endoscopists to encircle, lift, compress and treat tissue with precision. Utilizing proprietary Endolastic™ technology, EndoBand is designed for safety, effectiveness, versatility and efficiency across a variety of endoscopic procedures. All tissue target areas can be treated with MAC sedation, removing the necessity for hospital-based environments.          </p>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8">Anatomical Indications</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Gastric Esophageal Junction",
                description: "EndoBand's Medium (M) size is expertly designed for reliable tissue encircling and lifting, ensuring precise anatomical positioning in the gastric esophageal junction.",
                features: [
                  "Enhanced control for tissue encircling",
                  "Optimized for precise lifting and positioning",
                  "Ideal for GEJ-specific treatments"
                ],
                cta: "Buy Now"
              },
              {
                title: "Greater Curve of the Stomach",
                description: "The Large (L) EndoBand provides dependable tissue compression and lifting capabilities, ensuring consistent management across broader gastric regions.",
                features: [
                  "Adaptable to various gastric regions",
                  "Consistent and effective tissue capture",
                  "Designed for controlled application and flexibility"
                ],
                cta: "Buy Now"
              },
              {
                title: "Esophageal Varices",
                description: "EndoBand Small (S) is meticulously crafted for accurate tissue compression and encircling, enabling precision and control in esophageal varix procedures.",
                features: [
                  "Precision handling for smaller anatomical regions",
                  "Enhanced accuracy for delicate applications",
                  "Designed for specific esophageal requirements"
                ],
                cta: "Buy Now"
              }
            ].map((indication, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                className="bg-white p-6 rounded-lg shadow-lg"
              >
                <h3 className="text-xl font-semibold mb-4">{indication.title}</h3>
                <p className="text-gray-600 mb-4">{indication.description}</p>
                <ul className="space-y-2 mb-6">
                  {indication.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start">
                      <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-sm text-gray-700">{feature}</span>
                    </li>
                  ))}
                </ul>
                <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full text-white bg-orange-600 hover:bg-orange-700 transition duration-300">
                  {indication.cta} <ChevronRight className="ml-1 h-4 w-4" />
                </button>
              </motion.div>
            ))}
          </div>
        </section>

        <section id="technical-specs" className="mb-24 bg-white py-16 rounded-2xl shadow-lg">
          <div className="max-w-4xl mx-auto px-4">
            <h2 className="text-3xl font-bold mb-12 text-center">Technical Specifications</h2>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                { label: "Band Material", value: "Medical-grade elastomer" },
                { label: "Compatibility", value: "Standard endoscopic equipment" },
                { label: "Sterilization", value: "EO sterilized" },
                { label: "Packaging", value: "Single-use, sterile packaging" },
                { label: "Storage", value: "Room temperature" },
                { label: "Shelf Life", value: "24 months" }
              ].map((spec, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg font-semibold mb-2">{spec.label}</h3>
                  <p className="text-gray-700">{spec.value}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="benefits" className="mb-24 bg-gradient-to-br from-orange-500 to-orange-600 text-white py-16 rounded-2xl">
          <div className="max-w-4xl mx-auto px-4">
            <h2 className="text-3xl font-bold mb-12 text-center">Clinical Benefits</h2>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                { title: "Precision", description: "Accurate tissue capture and retention" },
                { title: "Efficiency", description: "Streamlined procedure workflow" },
                { title: "Versatility", description: "Multiple application possibilities" },
                { title: "Safety", description: "Enhanced procedural safety features" },
                { title: "Consistency", description: "Reliable performance across procedures" },
                { title: "Cost-Effective", description: "Optimized resource utilization" }
              ].map((benefit, index) => (
                <div key={index} className="bg-white bg-opacity-10 p-6 rounded-lg">
                  <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="faq" className="mb-24">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
            <div className="space-y-6">
              {[
                {
                  question: "What is EndoBand used for?",
                  answer: "EndoBand is designed for tissue management, providing encircling, lifting, compressing, and treating solutions for various anatomical needs."
                },
                {
                  question: "Can it treat specific conditions?",
                  answer: "EndoBand is not marketed for any specific disease treatment. It's a versatile tool for general tissue support applications."
                },
                {
                  question: "How do I choose the right size?",
                  answer: "Our configurator can help you identify the ideal size based on anatomical location and tissue dimensions."
                },
                {
                  question: "Is EndoBand FDA-approved?",
                  answer: "EndoBand is cleared for general tissue management indications. It is not approved for specific disease treatments."
                },
                {
                  question: "Who can use EndoBand?",
                  answer: "EndoBand is intended for use by qualified medical professionals in clinical settings."
                },
                {
                  question: "What materials are used in EndoBand?",
                  answer: "EndoBand is crafted with biocompatible materials designed for safety and effectiveness in medical applications."
                },
                {
                  question: "How do I order EndoBand?",
                  answer: "You can order directly through our website by selecting your size and quantity."
                }
              ].map((faq, index) => (
                <div 
                  key={index} 
                  className="bg-white rounded-lg shadow-md overflow-hidden"
                >
                  <div className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {faq.question}
                    </h3>
                    <p className="text-gray-600">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="mb-24 bg-gray-100 rounded-2xl p-12">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Ready to Learn More?</h2>
            <p className="text-xl mb-8">
              Contact us today to discover how EndoBand can elevate your practice with precision and versatility.
            </p>
            <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-orange-600 hover:bg-orange-700 transition duration-300">
              Contact Sales <ChevronRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </section>
      </main>
    </div>
  );
}

export default EndoBand; 