import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Check, ChevronRight, Menu, X, ChevronLeft, Play } from 'lucide-react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Partners from './pages/Partners';
import FAQ from './pages/FAQ';
import GERDFAQ from './pages/GERDFAQ';
import BMIFAQ from './pages/BMIFAQ';
import DMRFAQ from './pages/DMRFAQ';
import GERDProductPage from './pages/GERDProductPage';
import OBESITYProductPage from './pages/OBESITYProductPage';
import DIABETESProductPage from './pages/DIABETESProductPage';
import About from './pages/About';
import Contact from './pages/Contact';
import COE from './pages/COE';
import EndolasticVsOzempic from './pages/EndolasticVsOzempic';
import EndoBand from './pages/endoband';
import { HelmetProvider } from 'react-helmet-async';


// Import the logo images
import EndoGERDLogo from './assets/images/ENDOGERD.png';
import EndoDMRLogo from './assets/images/ENDODMR.png';
import EndoBMILogo from './assets/images/ENDOBMI.png';

// Import the new image
import bandingImage from './assets/images/banding.png';

// Import both images
import bandImage from './assets/images/1.png';
import bandInUseImage from './assets/images/2.png';  // Changed from .jpg to .png
import endoBandsImage from './assets/images/3.png'; 

// Add EndoBand logo import
import EndoBandLogo from './assets/images/ENDOBAND.png';  // You'll need to add this image

const ConvertKitForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://f.convertkit.com/ckjs/ck.5.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <form action="https://app.convertkit.com/forms/7175340/subscriptions" className="seva-form formkit-form" method="post" data-sv-form="7175340" data-uid="bc94d309ba" data-format="inline" data-version="5" min-width="400 500 600 700 800" style={{backgroundColor: "rgb(255, 255, 255)", borderRadius: "6px"}}>
        <div data-style="clean">
          <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
          <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
            <div className="formkit-field">
              <input className="formkit-input" aria-label="First Name" name="fields[first_name]" placeholder="First Name" type="text" style={{color: "rgb(0, 0, 0)", borderColor: "rgb(227, 227, 227)", borderRadius: "4px", fontWeight: 400}} />
            </div>
            <div className="formkit-field">
              <input className="formkit-input" name="email_address" aria-label="Email Address" placeholder="Email Address" required="" type="email" style={{color: "rgb(0, 0, 0)", borderColor: "rgb(227, 227, 227)", borderRadius: "4px", fontWeight: 400}} />
            </div>
            <button data-element="submit" className="formkit-submit formkit-submit" style={{color: "rgb(255, 169, 0)", backgroundColor: "rgb(255, 255, 255)", borderRadius: "13px", fontWeight: 700}}>
              <div className="formkit-spinner">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span>Download InfoSheet</span>
            </button>
          </div>
        </div>
      </form>
      <style>{`
        .formkit-form[data-uid="bc94d309ba"] * {
          box-sizing: border-box;
        }
        .formkit-form[data-uid="bc94d309ba"] {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        .formkit-form[data-uid="bc94d309ba"] legend {
          border: none;
          font-size: inherit;
          margin-bottom: 10px;
          padding: 0;
          position: relative;
          display: table;
        }
        .formkit-form[data-uid="bc94d309ba"] fieldset {
          border: 0;
          padding: 0.01em 0 0 0;
          margin: 0;
          min-width: 0;
        }
        .formkit-form[data-uid="bc94d309ba"] body:not(:-moz-handler-blocked) fieldset {
          display: table-cell;
        }
        .formkit-form[data-uid="bc94d309ba"] p {
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
        .formkit-form[data-uid="bc94d309ba"][data-format="modal"] {
          display: none;
        }
        .formkit-form[data-uid="bc94d309ba"][data-format="slide in"] {
          display: none;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-input,
        .formkit-form[data-uid="bc94d309ba"] .formkit-select,
        .formkit-form[data-uid="bc94d309ba"] .formkit-checkboxes {
          width: 100%;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-button,
        .formkit-form[data-uid="bc94d309ba"] .formkit-submit {
          border: 0;
          border-radius: 5px;
          color: #ffffff;
          cursor: pointer;
          display: inline-block;
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 15px;
          overflow: hidden;
          padding: 0;
          position: relative;
          vertical-align: middle;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-button:hover,
        .formkit-form[data-uid="bc94d309ba"] .formkit-submit:hover,
        .formkit-form[data-uid="bc94d309ba"] .formkit-button:focus,
        .formkit-form[data-uid="bc94d309ba"] .formkit-submit:focus {
          outline: none;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-button:hover > span,
        .formkit-form[data-uid="bc94d309ba"] .formkit-submit:hover > span,
        .formkit-form[data-uid="bc94d309ba"] .formkit-button:focus > span,
        .formkit-form[data-uid="bc94d309ba"] .formkit-submit:focus > span {
          background-color: rgba(0,0,0,0.1);
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-button > span,
        .formkit-form[data-uid="bc94d309ba"] .formkit-submit > span {
          display: block;
          -webkit-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
          padding: 12px 24px;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-input {
          background: #ffffff;
          font-size: 15px;
          padding: 12px;
          border: 1px solid #e3e3e3;
          -webkit-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;
          line-height: 1.4;
          margin: 0;
          -webkit-transition: border-color ease-out 300ms;
          transition: border-color ease-out 300ms;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-input:focus {
          outline: none;
          border-color: #1677be;
          -webkit-transition: border-color ease 300ms;
          transition: border-color ease 300ms;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-input::-webkit-input-placeholder {
          color: inherit;
          opacity: 0.8;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-input::-moz-placeholder {
          color: inherit;
          opacity: 0.8;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-input:-ms-input-placeholder {
          color: inherit;
          opacity: 0.8;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-input::placeholder {
          color: inherit;
          opacity: 0.8;
        }
        .formkit-form[data-uid="bc94d309ba"] [data-group="dropdown"] {
          position: relative;
          display: inline-block;
          width: 100%;
        }
        .formkit-form[data-uid="bc94d309ba"] [data-group="dropdown"]::before {
          content: "";
          top: calc(50% - 2.5px);
          right: 10px;
          position: absolute;
          pointer-events: none;
          border-color: #4f4f4f transparent transparent transparent;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          height: 0;
          width: 0;
          z-index: 999;
        }
        .formkit-form[data-uid="bc94d309ba"] [data-group="dropdown"] select {
          height: auto;
          width: 100%;
          cursor: pointer;
          color: #333333;
          line-height: 1.4;
          margin-bottom: 0;
          padding: 0 6px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          font-size: 15px;
          padding: 12px;
          padding-right: 25px;
          border: 1px solid #e3e3e3;
          background: #ffffff;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-fields[data-stacked="false"] {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          margin: 0 auto;
        }
        .formkit-form[data-uid="bc94d309ba"] .formkit-field,
        .formkit-form[data-uid="bc94d309ba"] .formkit-submit {
          margin: 0 0 15px 0;
          -webkit-flex: 1 0 100%;
          -ms-flex: 1 0 100%;
          flex: 1 0 100%;
        }
        .formkit-form[data-uid="bc94d309ba"][min-width~="700"] [data-style="clean"],
        .formkit-form[data-uid="bc94d309ba"][min-width~="800"] [data-style="clean"] {
          padding: 10px;
        }
        .formkit-form[data-uid="bc94d309ba"][min-width~="700"] .formkit-fields[data-stacked="false"],
        .formkit-form[data-uid="bc94d309ba"][min-width~="800"] .formkit-fields[data-stacked="false"] {
          margin-left: -5px;
          margin-right: -5px;
        }
        .formkit-form[data-uid="bc94d309ba"][min-width~="700"] .formkit-fields[data-stacked="false"] .formkit-field,
        .formkit-form[data-uid="bc94d309ba"][min-width~="800"] .formkit-fields[data-stacked="false"] .formkit-field,
        .formkit-form[data-uid="bc94d309ba"][min-width~="700"] .formkit-fields[data-stacked="false"] .formkit-submit,
        .formkit-form[data-uid="bc94d309ba"][min-width~="800"] .formkit-fields[data-stacked="false"] .formkit-submit {
          margin: 0 5px 15px 5px;
        }
        .formkit-form[data-uid="bc94d309ba"][min-width~="700"] .formkit-fields[data-stacked="false"] .formkit-field,
        .formkit-form[data-uid="bc94d309ba"][min-width~="800"] .formkit-fields[data-stacked="false"] .formkit-field {
          -webkit-flex: 100 1 auto;
          -ms-flex: 100 1 auto;
          flex: 100 1 auto;
        }
        .formkit-form[data-uid="bc94d309ba"][min-width~="700"] .formkit-fields[data-stacked="false"] .formkit-submit,
        .formkit-form[data-uid="bc94d309ba"][min-width~="800"] .formkit-fields[data-stacked="false"] .formkit-submit {
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
        }
      `}</style>
    </>
  );
};

function App() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showLearnMoreForm, setShowLearnMoreForm] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeCondition, setActiveCondition] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const images = [bandImage, bandInUseImage, endoBandsImage];
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const conditions = [
    {
      title: 'GERD: Anti-Reflux Band Mucosectomy (ARBM)',
      description: 'Our Endolastic™ technology provides a minimally invasive solution for chronic acid reflux, offering long-term relief without the need for ongoing medication.',
      logo: EndoGERDLogo,
      status: 'Pending FDA Approval',
      details: {
        what: 'Minimally invasive procedure restoring lower esophageal sphincter (LES) function',
        how: 'Uses specialized elastomeric bands with unique shape for secure tissue retention and controlled pressure, promoting natural LES remodeling',
        impact: 'Effective GERD symptom relief, reduced PPI dependence, rapid recovery with lasting results',
        advantages: '30-minute outpatient procedure, minimal recovery time, freedom from long-term pharmacotherapy',
      },
      hasVideo: true,
    },
    {
      title: 'Obesity: Endoscopic Band Gastroplasty (EBG)',
      description: 'Endolastic\'s innovative approach helps patients achieve sustainable weight loss through a quick, outpatient procedure with minimal recovery time.',
      logo: EndoBMILogo,
      status: 'Pending FDA Approval',
      details: {
        what: 'Minimally invasive procedure reducing and reshaping stomach volume targeting the greater curvature',
        how: 'Specialized SafeTrap™ barrels and WindowLock™ bands capture greater tissue volume, including muscularis propria, for enhanced effectiveness',
        impact: 'Achieves >10% total body weight loss or 25% excess weight loss',
        advantages: 'No incisions or stapling, preserves gut biome, minimizes GERD risk, reversible and repeatable',
      },
    },
    {
      title: 'Type 2 Diabetes: Duodenal Mucosal Resurfacing (DMR)',
      description: 'By addressing obesity, our technology aids in managing type 2 diabetes, potentially reducing medication dependence and improving overall health outcomes.',
      logo: EndoDMRLogo,
      status: 'Pending FDA Approval',
      details: {
        what: 'Non-pharmacologic procedure targeting the root cause of T2DM by regenerating healthy duodenal mucosa',
        how: 'Novel ligation-based approach using SafeTrap™ barrels and WindowLock™ bands for controlled mechanical pressure necrosis followed by the healthy regeneration of duodenal mucosa',
        impact: 'Improves insulin sensitivity, lowers HbA1c levels, reduces need for antidiabetic medications',
        advantages: 'Non-ablative, minimally invasive, safer than current experimental DMR methods, avoids deep tissue damage, suitable for outpatient settings',
      },
    },
    {
      title: 'EndoBand: Precision Tissue Management',
      description: 'EndoBand utilizes Endolastic™ technology to deliver versatile and precise support for tissue management, empowering professionals with a minimally invasive, reliable solution.',
      logo: EndoBandLogo,
      status: 'Available Now',
      details: {
        what: 'Versatile tissue management system for various endoscopic procedures',
        how: 'Leverages Endolastic™ technology for precise tissue control and manipulation',
        impact: 'Enables effective tissue management across multiple applications',
        advantages: 'Minimally invasive, reliable, and adaptable to different procedural needs',
      },
    },
  ];

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    setTimeout(() => setIsFormSubmitted(false), 3000);
  };

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <HelmetProvider>
      <Router>
        <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <Navigation />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={
                <main>
                  <section className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-16">
                    <div className="container mx-auto px-4">
                      <div className="flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pr-8">
                          <motion.h1
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="text-4xl md:text-5xl font-bold mb-4 leading-tight"
                          >
                            Revolutionizing Endoscopic Treatment
                          </motion.h1>
                          <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                            className="text-lg md:text-xl mb-6"
                          >
                            Empowering endoscopists to transform lives through innovative, minimally invasive solutions for GERD, Obesity, and Diabetes
                          </motion.p>
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: 0.4 }}
                          >
                            {!showLearnMoreForm ? (
                              <button
                                onClick={() => setShowLearnMoreForm(true)}
                                className="inline-block bg-white text-orange-600 font-semibold py-2 px-6 rounded-full text-lg hover:bg-orange-100 transition-colors"
                              >
                                Learn More
                              </button>
                            ) : (
                              <div className="bg-white p-4 rounded-lg shadow-lg max-w-md">
                                <form onSubmit={handleFormSubmit} className="flex flex-col space-y-4">
                                  <input
                                    type="text"
                                    placeholder="First Name"
                                    className="flex-grow px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 text-gray-900"
                                    required
                                  />
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    className="flex-grow px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 text-gray-900"
                                    required
                                  />
                                  <button
                                    type="submit"
                                    className="bg-orange-600 text-white px-4 py-2 rounded-md hover:bg-orange-700 transition-colors"
                                  >
                                    Submit
                                  </button>
                                </form>
                              </div>
                            )}
                          </motion.div>
                        </div>
                        <div className="w-full md:w-1/2 md:pl-4">
                          <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8 }}
                            className="bg-white p-4 rounded-lg shadow-xl"
                            style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}
                          >
                            <div className="relative" style={{ paddingBottom: '75%' }}>
                              <AnimatePresence mode="wait">
                                <motion.img
                                  key={currentImage}
                                  src={images[currentImage]}
                                  alt={currentImage === 0 ? "Endolastic Band" : "Endolastic Band in Use"}
                                  className="absolute top-0 left-0 w-full h-full object-contain rounded-lg"
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  transition={{ duration: 0.5 }}
                                />
                              </AnimatePresence>
                            </div>
                            <button
                              onClick={prevImage}
                              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-all"
                            >
                              <ChevronLeft className="w-6 h-6 text-orange-600" />
                            </button>
                            <button
                              onClick={nextImage}
                              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-all"
                            >
                              <ChevronRight className="w-6 h-6 text-orange-600" />
                            </button>
                          </motion.div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section id="solutions" className="py-24">
                    <div className="container mx-auto px-4">
                      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">3 Chronic Conditions. 1 Groundbreaking Solution.</h2>
                      
                      {/* First render the EndoBand product in its own row */}
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="bg-white p-6 rounded-lg shadow-lg flex flex-col relative max-w-xl mx-auto mb-12"
                      >
                        {conditions.slice(3, 4).map((condition) => (
                          <div key={condition.title}>
                            <div className="absolute top-4 right-4">
                              <span className="px-2 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                {condition.status}
                              </span>
                            </div>
                            <div className="h-24 flex items-center justify-center mb-4">
                              <img 
                                src={condition.logo} 
                                alt={condition.title} 
                                className="max-h-full w-auto"
                              />
                            </div>
                            <div className="flex-grow flex flex-col">
                              <h3 className="text-xl font-semibold mb-2 text-center">
                                {condition.title}
                              </h3>
                              <p className="text-gray-600 mb-4 text-center">
                                {condition.description}
                              </p>
                              <div className="flex justify-center items-center mt-auto">
                                <Link
                                  to="/products/endoband"
                                  className="text-orange-600 hover:text-orange-800 font-semibold flex items-center"
                                >
                                  Learn More <ChevronRight className="w-5 h-5 ml-1" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </motion.div>

                      {/* Then render the other three products in a grid */}
                      <div className="grid md:grid-cols-3 gap-8">
                        {conditions.slice(0, 3).map((condition, index) => (
                          <motion.div
                            key={condition.title}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: index * 0.2 }}
                            className="bg-white p-6 rounded-lg shadow-lg flex flex-col relative"
                          >
                            <div className="absolute top-4 right-4">
                              <span className={`px-2 py-1 rounded-full text-sm font-medium ${
                                condition.status === 'Available Now' 
                                  ? 'bg-green-100 text-green-800'
                                  : 'bg-yellow-100 text-yellow-800'
                              }`}>
                                {condition.status}
                              </span>
                            </div>
                            <div className="h-24 flex items-center justify-center mb-4">
                              <img 
                                src={condition.logo} 
                                alt={condition.title} 
                                className="max-h-full w-auto"
                              />
                            </div>
                            <div className="flex-grow flex flex-col">
                              <h3 className="text-xl font-semibold mb-2 min-h-[3rem]">
                                {condition.title}
                              </h3>
                              <p className="text-gray-600 mb-4 flex-grow">
                                {condition.description}
                              </p>
                              <div className="flex justify-between items-center mt-auto">
                                <Link
                                  to="/products/endoband"
                                  className="text-orange-600 hover:text-orange-800 font-semibold flex items-center"
                                >
                                  Learn More <ChevronRight className="w-5 h-5 ml-1" />
                                </Link>
                                {condition.hasVideo && (
                                  <button
                                    onClick={() => setIsVideoModalOpen(true)}
                                    className="flex items-center text-orange-600 hover:text-orange-800 font-semibold"
                                  >
                                    <Play className="w-5 h-5 mr-1" />
                                    Watch Demo
                                  </button>
                                )}
                              </div>
                            </div>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </section>

                  <section id="innovation" className="bg-gray-100 py-24">
                    <div className="container mx-auto px-4">
                      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">The Endolastic™ Difference</h2>
                      <div className="flex flex-col md:flex-row items-center justify-center">
                        <div className="md:w-1/2 mb-8 md:mb-0">
                          <div className="w-4/5 mx-auto">
                            <img
                              src={bandingImage}
                              alt="Endolastic™ Banding Illustration"
                              className="rounded-lg shadow-lg w-full"
                            />
                          </div>
                        </div>
                        <div className="md:w-1/2 md:pl-8">
                          <ul className="space-y-4">
                            {[
                              'Minimally invasive ligation based procedure',
                              'Targets dysfunctional GI tissue',
                              'Quick recovery time',
                              'Customizable for various applications',
                              'Natural tissue regeneration',
                              'Repeatable and adjustable',
                            ].map((feature, index) => (
                              <motion.li
                                key={index}
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="flex items-start"
                              >
                                <Check className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" />
                                <span>{feature}</span>
                              </motion.li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section id="how-it-works" className="py-24">
                    <div className="container mx-auto px-4">
                      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">How Endolastic Therapy Works</h2>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                        {[
                          {
                            title: 'Minimally Invasive',
                            description: 'Endolastic\'s WindowLock™ bands are applied endoscopically, meaning no incisions are needed.',
                            details: [
                              'Low Risk of Complications: Reduced risk of infection and complications, with no incisions required.',
                              'Outpatient Procedure: Can be performed in an outpatient setting.',
                            ],
                            icon: '🔬',
                          },
                          {
                            title: 'Quick Procedure',
                            description: 'Endolastic procedures typically takes less than 30 minutes, with minimal recovery time.',
                            details: [
                              'Quick Recovery: Patients experience faster recovery times compared to surgical procedures.',
                              'Patient Comfort: Conscious sedation reduces the need for general anesthesia, enhancing patient comfort.',
                            ],
                            icon: '⏱️',
                          },
                          {
                            title: 'Effective Results',
                            description: 'Patients experience significant improvement in symptoms, often within days of the procedure.',
                            details: [
                              'Natural Tissue Regeneration: The innovative ligation based pressure zones stimulate the body\'s natural healing processes, regenerating healthy tissue.',
                              'Repeatable: The procedure can be safely repeated if necessary, allowing for adjustments or revisions.',
                            ],
                            icon: '📈',
                          },
                        ].map((step, index) => (
                          <motion.div
                            key={step.title}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, delay: index * 0.2 }}
                            className="bg-white p-6 rounded-lg shadow-lg"
                          >
                            <div className="text-4xl mb-4">{step.icon}</div>
                            <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                            <p className="text-gray-600 mb-4">{step.description}</p>
                            <ul className="space-y-2">
                              {step.details.map((detail, detailIndex) => (
                                <li key={detailIndex} className="flex items-start">
                                  <ChevronRight className="w-5 h-5 text-orange-500 mr-2 flex-shrink-0" />
                                  <span className="text-sm">{detail}</span>
                                </li>
                              ))}
                            </ul>
                          </motion.div>
                        ))}
                      </div>
                      <div className="bg-gray-100 rounded-lg p-8">
                        <h3 className="text-2xl font-semibold mb-6 text-center">The Endolastic™ Process</h3>
                        <p className="text-lg text-center mb-8 text-gray-700">
                          Using calibrated ligation-induced necrosis, the dysfunctional tissue is safely remodeled, promoting the regeneration of healthy, functional tissue.
                        </p>
                        <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
                          {[
                            { step: 1, title: 'Assessment', description: 'Doctor evaluates patients condition and determines suitability for Endolastic therapy.' },
                            { step: 2, title: 'Application', description: 'Bands are applied endoscopically to the target dysfunctional tissue area.' },
                            { step: 3, title: 'Recovery', description: 'Patient recovers quickly, often returning to normal activities within days.' },
                            { step: 4, title: 'Results', description: 'Patients experience significant symptom improvement and better quality of life.' },
                          ].map((process) => (
                            <div key={process.step} className="mb-6 md:mb-0 md:w-1/4 text-center">
                              <div className="bg-white border border-orange-200 rounded-lg p-4 shadow-sm">
                                <div className="bg-orange-600 text-white rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                                  {process.step}
                                </div>
                                <h4 className="font-semibold mb-2">{process.title}</h4>
                                <p className="text-sm">{process.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section id="for-doctors" className="bg-gray-100 py-24">
                    <div className="container mx-auto px-4">
                      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">For Endoscopists</h2>
                      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8 }}
                          className="bg-white rounded-lg shadow-md p-6"
                        >
                          <h3 className="text-xl font-semibold mb-4">Expand Your Practice</h3>
                          <p className="mb-4">Offer cutting-edge treatments to your patients and stay at the forefront of endoscopic innovations.</p>
                        </motion.div>
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8, delay: 0.2 }}
                          className="bg-white rounded-lg shadow-md p-6"
                        >
                          <h3 className="text-xl font-semibold mb-4">Training & Support</h3>
                          <p className="mb-4">Access training programs and ongoing support to quickly become proficient in Endolastic™ ligation therapy.</p>
                        </motion.div>
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8, delay: 0.4 }}
                          className="bg-white rounded-lg shadow-md p-6"
                        >
                          <h3 className="text-xl font-semibold mb-4">Pricing Incentives</h3>
                          <p className="mb-4">Benefit from our performance-based pricing model and volume-driven incentives designed to align with your success.</p>
                        </motion.div>
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.8, delay: 0.6 }}
                          className="bg-white rounded-lg shadow-md p-6"
                        >
                          <h3 className="text-xl font-semibold mb-4">Influence Product Development</h3>
                          <p className="mb-4">Participate in our collaborative network and help shape the future of endoscopic treatments.</p>
                        </motion.div>
                      </div>
                      <div className="mt-12 text-center">
                        <Link
                          to="/coe"
                          className="inline-block bg-orange-600 text-white px-6 py-3 rounded-md hover:bg-orange-700 transition duration-300"
                        >
                          Visit Center of Excellence
                        </Link>
                      </div>
                    </div>
                  </section>

                  <section id="for-distributors" className="py-24">
                    <div className="container mx-auto px-4">
                      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">For Distributors</h2>
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="bg-white rounded-lg shadow-md p-8 max-w-2xl mx-auto"
                      >
                        <h3 className="text-xl font-semibold mb-4">Join Our Network</h3>
                        <p className="mb-6">We are actively assessing strategic partnership opportunities with a select group of distributors, as part of our comprehensive global go-to-market strategy, encompassing both direct and distribution-led channels.</p>
                        <ul className="space-y-2 mb-6">
                          {[
                            'High-demand, innovative products',
                            'Significant market potential in GERD, obesity, and diabetes',
                            'Cost-effective solutions with high margins',
                            'Easy to demonstrate and train physicians',
                            'Complements existing endoscopic product lines',
                            'Potential for recurring revenue through disposable components',
                          ].map((benefit, index) => (
                            <li key={index} className="flex items-start">
                              <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                              <span>{benefit}</span>
                            </li>
                          ))}
                        </ul>
                        <a
                          href="/partners"
                          className="inline-block bg-orange-600 text-white px-6 py-3 rounded-md hover:bg-orange-700 transition duration-300"
                        >
                          Partner With Us
                        </a>
                      </motion.div>
                    </div>
                  </section>

                  <section id="contact" className="bg-orange-500 text-white py-24">
                    <div className="container mx-auto px-4">
                      <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">Ready to Transform Patient Care?</h2>
                      <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-800 mb-4">Get More Information</h3>
                        <p className="text-gray-600 mb-4">Learn more about Endolastic's innovative solutions.</p>
                        <ConvertKitForm />
                      </div>
                    </div>
                  </section>
                </main>
              } />
              <Route path="/partners" element={<Partners />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/faq/gerd" element={<GERDFAQ />} />
              <Route path="/faq/bmi" element={<BMIFAQ />} />
              <Route path="/faq/dmr" element={<DMRFAQ />} />
              <Route path="/products/gerd" element={<GERDProductPage />} />
              <Route path="/products/obesity" element={<OBESITYProductPage />} />
              <Route path="/products/type2diabetes" element={<DIABETESProductPage />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/coe" element={<COE />} />
              <Route path="/endolastic-vs-ozempic" element={<EndolasticVsOzempic />} />
              <Route path="/products/endoband" element={<EndoBand />} />
            </Routes>
          </main>
          <Footer />
        </div>
        {/* Video Modal */}
        {isVideoModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
          >
            <div className="relative w-full max-w-4xl bg-black rounded-lg overflow-hidden">
              <button
                onClick={() => setIsVideoModalOpen(false)}
                className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
              >
                <X className="w-6 h-6" />
              </button>
              <div className="aspect-video">
                <iframe
                  className="w-full h-full"
                  src="https://www.youtube.com/embed/PnsXfDu5nrE?autoplay=1"
                  title="Endolastic ARBM Procedure"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </motion.div>
        )}
      </Router>
    </HelmetProvider>
  );
}

export default App;